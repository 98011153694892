import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardArrowLeft, MdOutlineSupportAgent, MdCurrencyRupee } from "react-icons/md";
import { SiNeteasecloudmusic } from "react-icons/si";
import { Card, Container, Modal } from "react-bootstrap";
import { TfiWallet } from "react-icons/tfi";
import { IoMdRefresh } from "react-icons/io";
import { AiFillSound } from "react-icons/ai";
import { FaGripfire } from "react-icons/fa";
import axios from "axios";
import { useCredit } from './CreditContext';

export default function WingoChart(){
  const user_id = localStorage.getItem("user_id");
  const isLoading = useRef(false);
  const [loading, setLoading] = useState(false);
  const [images, setImage] = useState([]);
  const [userData, setUserData] = useState(null);

  const { credit, updateCredit } = useCredit();

  useEffect(() => {
    if (!isLoading.current) {
      fetchGames();
    }
  }, []);

  const fetchGames = async () => {
    try {
      isLoading.current = true;
      const url = `${process.env.REACT_APP_API_URL}profile-list`;
  
      setLoading(true);
  
      const config = {
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: { user_id },
      };
  
      const response = await axios(config);
  
      const responseData = response.data.data;
      setUserData(responseData);
      const credit = responseData.credit;
      updateCredit(credit);
  
      isLoading.current = false;
      setLoading(false);
    } catch (error) {
      isLoading.current = false;
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

  const placeBet = async () => {
    try {
      // Your code to place the bet goes here

      // After placing the bet, refresh the credit
      await fetchGames();
    } catch (error) {
      console.error("Error placing bet:", error);
    }
  };

  return (
    <section className="wingoheader">
      <div className="wingo padding_all">
        <div className="topheader p-2 bg-color-all d-flex justify-content-between align-items-center">
          <Link to="/home" className="text-white text-2xl">
            <MdOutlineKeyboardArrowLeft />
          </Link>
          <h4 className="mb-0 text-white">Wingo</h4>
          <div className="d-flex">
            <Link to="/home" className="w-5 text-white">
              <MdOutlineSupportAgent />
            </Link>
            <SiNeteasecloudmusic className="w-5 text-white" />
          </div>
        </div>
        <div className="allbefore position-relative">
          <Container>
            <Card className="mt-2 points_left_right">
              <Card.Body>
                <h3 className="text-dark d-flex mb-0 align-items-center gap-1 justify-content-center">
                  <MdCurrencyRupee className="text-md" />
                  {credit} <IoMdRefresh className="text-sm text-muted" />
                </h3>
                <p className="d-flex mb-0 align-items-center gap-1 justify-content-center">
                  <TfiWallet />
                  Wallet Balance
                </p>
                <div className="d-flex justify-content-evenly align-items-center">
                  <Link
                    to="/Withdraw"
                    className="buttonall no-underline rounded-full"
                    style={{ "--bgcolor": "#fa7638" }}
                  >
                    <span>Withdraw</span>
                  </Link>
                  <Link
                    to="/Deposit"
                    className="buttonall no-underline rounded-full"
                    style={{ "--bgcolor": "#90403f" }}
                  >
                    <span>Deposit</span>
                  </Link>
                </div>
              </Card.Body>
            </Card>
            <div className="Marquee_Tag bg-white">
              <div className="d-flex justify-content-between align-items-center">
                <div className="h-25 w-28 sound_icon">
                  <AiFillSound />
                </div>
                <div className="Notice">
                  <p className="">
                    Our customer service never sends a link to the member. If you
                    received a link from someone who proclaimed as customer service, do not click the link to prevent being hacked
                    or losing data. Thank you
                  </p>
                </div>
                <div className="details">
                  <Link to="/Details" style={{ textDecoration: "none" }}>
                    <button className="text-slate-900 text-white d-flex">
                      <FaGripfire />
                      Details
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </section>
  );
}
