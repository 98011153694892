import React, { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import { Container, Card } from "react-bootstrap";
import { FaRegCopy } from "react-icons/fa";
import { IoIosArrowDropright } from "react-icons/io";
import {useLocation} from 'react-router-dom';
import { useAppContext } from './AppContext';

export default function WingoChart() {
  const [showaccordian, setShowaccordian] = useState(null);
  const [userListData, setUserListData] = useState(null);
  const user_id = localStorage.getItem("user_id");
  const isLoading = useRef(false);
  const [loading, setLoading] = useState(false);
  const category_slug = localStorage.getItem("category_slug");
  const subcategory_slug = localStorage.getItem("sub_category_slug");
  // const { params } = useAppContext();
  // const { categorySlug, subCategorySlug } = params;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  
  useEffect(() => {
    if (category_slug && subcategory_slug && !isLoading.current) {
    fetchcolorlist();
    }
  }, [category_slug, subcategory_slug]);

  const fetchcolorlist = async () => {
    try {
      isLoading.current = true;
      const url = `${process.env.REACT_APP_API_URL}color-bet-list`;

      setLoading(true);
      const config = {
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: { user_id, category_slug: category_slug, subcategory_slug: subcategory_slug },
      };

      const response = await axios(config);
      let responseData1 = response.data.data;

      // Sort responseData1 based on period_id in ascending order
      responseData1.sort((a, b) => b.period_id - a.period_id);

      setUserListData(responseData1);

      isLoading.current = false;
      setLoading(false);
    } catch (error) {
      isLoading.current = false;
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

  if (!userListData || userListData.length === 0) {
    return <p>No data available</p>;
  }

  const showing = (clickedItem) => {
    setShowaccordian(clickedItem === showaccordian ? null : clickedItem);
  };

  return (
    <section className="wingochart">
      <Container>
        <div className="wingo padding_all">
          <div className="history mt-2">
            <Tabs>
              <TabList>
                <Tab>
                  <button className="">Game History</button>
                </Tab>
                <Tab>
                  <button className="">Chart</button>
                </Tab>
                <Tab>
                  <button onClick={fetchcolorlist} className="">My History</button>
                </Tab>
              </TabList>

              <TabPanel>
                <table className="table-auto table table-striped history_table">
                  <thead>
                    <tr>
                      <th className="">Period</th>
                      <th className="">Number</th>
                      <th className="">Big Small</th>
                      <th className="">Color</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {userListData.map((item, index) => ( */}
                      <tr>
                        <td>756786578</td>
                        <td>
                          <span className="number">8765897895</span>
                        </td>
                        <td>small</td>
                        <td>
                          <div className="d-flex align-items-center justify-content-evenly">
                            <span className="w-2 h-2 rounded-full bg-danger d-inline-block"></span>
                            <span className="w-2 h-2 rounded-full bg-primary d-inline-block"></span>
                          </div>
                        </td>
                      </tr>
                    {/* ))} */}
                  </tbody>
                </table>
              </TabPanel>

              <TabPanel>
                <table className="table-auto table table-striped history_table">
                  <thead>
                    <tr>
                      <th className="">Period</th>
                      <th className="">Number</th>
                      <th className="">Big Small</th>
                      <th className="">Color</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {userListData.map((item, index) => ( */}
                      <tr>
                        <td>7456745648756</td>
                        <td>
                          <span className="number">7568576</span>
                        </td>
                        <td>big</td>
                        <td>
                          <div className="d-flex align-items-center justify-content-evenly">
                            <span className="w-2 h-2 rounded-full bg-danger d-inline-block"></span>
                            <span className="w-2 h-2 rounded-full bg-primary d-inline-block"></span>
                          </div>
                        </td>
                      </tr>
                    {/* ))} */}
                  </tbody>
                </table>
              </TabPanel>

              <TabPanel>
                <div className="myhistory">
                  <Card>
                    <Card.Body>
                      {userListData.map((item, index) => (
                        <React.Fragment key={index}>
                          <div
                            className="d-flex justify-content-between"
                            onClick={() => showing(item)}
                          >
                           
                           <div className="d-flex gap-2">
                           <div
  className="icon_profile"
  style={{
    background: item.number === "0"
      ? 'linear-gradient(to bottom right, violet, #fd565c)'
      : item.number === "5"
      ? 'linear-gradient(to bottom right, #40ad72, violet)'
      : item.color
  }}
>
  {item.big_small && !item.number && item.big_small}
  {item.number && `${item.number}`}
  {!item.big_small && !item.number && item.color && `${item.color}`}
</div>

                            
                             
                              <div className="text-start">
                                <h3 className="mb-0 pb-0 text-base">
                                  {item.period_id}
                                </h3>
                                <p className="mb-0 pb-0 text-sm text-muted">
                                  {item.datetime}
                                </p>
                              </div>
                            </div>
                            <div className="status_wingo">
                              <p className="status">Failed</p>
                              <p>
                                -<span>₹</span>
                                {item.tax}
                              </p>
                            </div>
                          </div>
                          <hr />

                          {showaccordian === item && (
                            <div className="details">
                              <h4 className="mt-3 text-start">Details</h4>
                              <ul className="p-0">
                                <li className="bg-slate-200 p-1 rounded-md mb-2">
                                  <div className="d-flex justify-content-between gap-2">
                                    <p className="mb-0 text-dark text-sm-custum whitespace-nowrap">
                                      Order number
                                    </p>
                                    <p className="d-flex align-items-center gap-1 mb-0 text-sm-custum text-muted">
                                      {item.orderid} <FaRegCopy />
                                    </p>
                                  </div>
                                </li>
                                <li className="bg-slate-200 p-1 rounded-md mb-2">
                                  <div className="d-flex justify-content-between gap-2">
                                    <p className="mb-0 text-dark text-sm-custum whitespace-nowrap">
                                      Period
                                    </p>
                                    <p className="d-flex align-items-center gap-1 mb-0 text-sm-custum text-muted">
                                      {item.period_id}
                                    </p>
                                  </div>
                                </li>
                                <li className="bg-slate-200 p-1 rounded-md mb-2">
                                  <div className="d-flex justify-content-between gap-2">
                                    <p className="mb-0 text-dark text-sm-custum whitespace-nowrap">
                                      Purchase amount
                                    </p>
                                    <p className="d-flex align-items-center gap-1 mb-0 text-sm-custum text-muted">
                                      €{item.amount}
                                    </p>
                                  </div>
                                </li>
                                <li className="bg-slate-200 p-1 rounded-md mb-2">
                                  <div className="d-flex justify-content-between gap-2">
                                    <p className="mb-0 text-dark text-sm-custum whitespace-nowrap">
                                      Quantity
                                    </p>
                                    <p className="d-flex align-items-center gap-1 mb-0 text-sm-custum text-muted">
                                      {item.quantity}1
                                    </p>
                                  </div>
                                </li>
                                <li className="bg-slate-200 p-1 rounded-md mb-2">
                                  <div className="d-flex justify-content-between gap-2">
                                    <p className="mb-0 text-dark text-sm-custum whitespace-nowrap">
                                      Amount after tax
                                    </p>
                                    <p className="d-flex align-items-center gap-1 mb-0 text-sm-custum text-muted">
                                      €{item.amount_after_tax}
                                    </p>
                                  </div>
                                </li>
                                <li className="bg-slate-200 p-1 rounded-md mb-2">
                                  <div className="d-flex justify-content-between gap-2">
                                    <p className="mb-0 text-dark text-sm-custum whitespace-nowrap">
                                      Tax
                                    </p>
                                    <p className="d-flex align-items-center gap-1 mb-0 text-sm-custum text-muted">
                                      {item.tax}
                                    </p>
                                  </div>
                                </li>
                                <li className="bg-slate-200 p-1 rounded-md mb-2">
                                  <div className="d-flex justify-content-between gap-2">
                                    <p className="mb-0 text-dark text-sm-custum whitespace-nowrap">
                                      Result
                                    </p>
                                    <p className="d-flex align-items-center gap-1 mb-0 text-sm-custum text-muted">
                                      {item.number} {item.color} {item.big_small}
                                    </p>
                                  </div>
                                </li>
                                <li className="bg-slate-200 p-1 rounded-md mb-2">
                                  <div className="d-flex justify-content-between gap-2">
                                    <p className="mb-0 text-dark text-sm-custum whitespace-nowrap">
                                      Select
                                    </p>
                                    <p className="d-flex align-items-center gap-1 mb-0 text-sm-custum text-muted">
                                      {item.big_small && `${item.big_small}`}
                                      {item.number && `${item.number}`}
                                      {item.color && `${item.color}`}
                                    </p>
                                  </div>
                                </li>
                                <li className="bg-slate-200 p-1 rounded-md mb-2">
                                  <div className="d-flex justify-content-between gap-2">
                                    <p className="mb-0 text-dark text-sm-custum whitespace-nowrap">
                                      Status
                                    </p>
                                    <p className="d-flex align-items-center gap-1 mb-0 text-sm-custum text-muted">
                                      {item.status}
                                    </p>
                                  </div>
                                </li>
                                <li className="bg-slate-200 p-1 rounded-md mb-2">
                                  <div className="d-flex justify-content-between gap-2">
                                    <p className="mb-0 text-dark text-sm-custum whitespace-nowrap">
                                      Win/lose
                                    </p>
                                    <p className="d-flex align-items-center gap-1 mb-0 text-sm-custum text-muted">
                                      €{item.win_lose}
                                    </p>
                                  </div>
                                </li>
                                <li className="bg-slate-200 p-1 rounded-md mb-2">
                                  <div className="d-flex justify-content-between gap-2">
                                    <p className="mb-0 text-dark text-sm-custum whitespace-nowrap">
                                      Order time
                                    </p>
                                    <p className="d-flex align-items-center gap-1 mb-0 text-sm-custum text-muted">
                                      {item.datetime}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </Card.Body>
                  </Card>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </Container>
    </section>
  );
}
